import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const { jwt } = useJwt(axios, {
    loginEndpoint: '/api/auth',
    // refreshEndpoint: '/api/auth-refresh',
    refreshEndpoint: `${process.env.VUE_APP_VITALSIGN_API_URL}/api/refresh-token`,
})

export default jwt
