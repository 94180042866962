import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueDebounce from 'vue-debounce'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'remixicon/fonts/remixicon.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//Custom helpers
import axiosInterceptors from '@/helpers/axios';

import 'vue-good-table/dist/vue-good-table.css'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
// import '@/libs/sweet-alerts'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// Vue.component('VueRemixicon', vueRemixicon);

Vue.use(VueSweetalert2, {
  cancelButtonText: 'Annuler',
});

import VueInputAutowidth from 'vue-input-autowidth';
Vue.use(VueInputAutowidth);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//Custom helpers registration
axiosInterceptors();

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueDebounce)

// Date / datetime str
Vue.mixin({
  methods: {
    dateToStr: d => (new Date(d)).toLocaleDateString(),
    dateTimeToStr: dt => `${(new Date(dt)).toLocaleDateString()} à ${(new Date(dt)).toLocaleTimeString()}`,
    sessionExpired: () => {
      Vue.$toast.error('Votre session a expiré. Veuillez vous reconnecter !', { timeout: 6000 });
      router.push('login');
    },
  },
});

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
