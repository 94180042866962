import axios from '@axios';
import { Vue } from 'vue-demi';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function intercept() {
    axios.interceptors.request.use(
        (request) => {
            request.config = {
                showToast: false,
                ...(request.config || {}),
            }

            if (request.config.showToast && request.config.requestToast) {
                request.config.requestToastId = Vue.$toast(
                    request.config.requestToast.title,
                    { id: "in-progress-modal" }
                );
            }
            return request;
        }
    );

    axios.interceptors.response.use(
        (response) => {
            const request = response.config;

            if (request.config.requestToastId) {
                Vue.$toast.dismiss("in-progress-modal");
            }

            if (request.config.showToast && request.config.responseToast) {
                Vue.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: request.config.responseToast.title || "Requête effectuée avec succès",
                            text: request.config.responseToast.body || null,
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    },
                    {
                        timeout: 4000,
                    }
                );
            }

            return response;
        },
        (error) => {
            const response = error.response;
            if (response.config.config.requestToastId) {
                Vue.$toast.dismiss("in-progress-modal");
            }
            console.log(response);
            if (response.data.errors) {
                if (Array.isArray(response.data.errors)) {
                    response.data.errors.forEach(error => {
                        Vue.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || "Une erreur est survenue",
                                    text: error,
                                    icon: "XIcon",
                                    variant: "danger",
                                },
                            },
                            {
                                timeout: 4000,
                            }
                        );
                    })
                } else {
                    for (const [key, value] of Object.entries(response.data.errors)) {
                        Vue.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || "Une erreur est survenue",
                                    text: value,
                                    icon: "XIcon",
                                    variant: "danger",
                                },
                            },
                            {
                                timeout: 4000,
                            }
                        );
                    }
                }
            } else {
                Vue.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: response.data.message || "Une erreur est survenue",
                            icon: "XIcon",
                            variant: "danger",
                        },
                    },
                    {
                        timeout: 4000,
                    }
                );
            }
            return Promise.reject(error);
        }
    );
}