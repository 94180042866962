import { _ } from "core-js";

export default {
    namespaced: true,
    state: () => ({
        id: null,
        dynamicFields: [],
        modules: [],
        template: null,
        title: null,
        type: null,
        hasHeader: false,
        hasFooter: false,
    }),
    getters: {

    },
    mutations: {
        //DYNAMIC FIELDS
        /**
         * Sets dynamics fields
         * @param {Object} state 
         * @param {Array} payload dynamic fields to replace current ones
         */
        SET_DYNAMIC_FIELDS(state, payload) {
            state.dynamicFields = payload;
        },
        /**
         * Adds a dynamic field
         * @param {Object} state 
         * @param {Object} payload dynamic field to be added
         */
        ADD_DYNAMIC_FIELD(state, payload) {
            state.dynamicFields.push(payload);
        },
        /**
         * Updates an existing dynamic field
         * @param {Object} state 
         * @param {Object} payload two properties: currentField (field found in state.dynamicFields) and editedField (updated field)
         */
        UPDATE_DYNAMIC_FIELD(state, payload) {
            let idx = state.dynamicFields.findIndex(field => _.isEqual(field, payload.currentField));
            state.dynamicFields[idx] = _.cloneDeep(payload.editedField);

            // if label has changed, updates formulas that contain it
            if (payload.currentField.label !== payload.editedField.label) {
                state.dynamicFields.forEach(field => {
                    if (field.type === "formula" && field.formulatype === "number" && field.formula.includes(payload.currentField.label)) {
                        field.formula = field.formula.replace(payload.currentField.label, payload.editedField.label);
                    } else if (field.type === "formula" && field.formulatype === "date" && field.formuladate.includes(payload.currentField.label)) {
                        field.formuladate = field.formuladate.replace(payload.currentField.label, payload.editedField.label);
                    }
                });
            }

        },
        /**
         * Deletes a dynamic field
         * @param {Object} state 
         * @param {Object} payload dynamic field to be deleted
         */
        DELETE_DYNAMIC_FIELD(state, payload) {
            const id = state.dynamicFields.findIndex(e => e.label === payload.label && e.type === payload.type)
            if (id !== -1) {
                state.dynamicFields.splice(id, 1);
            }
        },
        //MODULES
        /**
         * Adds a module
         * @param {Object} state 
         * @param {Object} payload module to be added
         */
        ADD_MODULE(state, payload) {
            state.modules.push(payload);
        },

        //ID
        /**
         * Sets the ID
         * @param {Object} state 
         * @param {String} payload title to be set
         */
        SET_ID(state, payload) {
            state.id = payload;
        },

        //TITLE
        /**
         * Sets a title
         * @param {Object} state 
         * @param {String} payload title to be set
         */
        SET_TITLE(state, payload) {
            state.title = payload;
        },

        //TYPE
        /**
         * Sets a type
         * @param {Object} state 
         * @param {String} payload document type: TO_FILL or FILLED
         */
        SET_TYPE(state, payload) {
            state.type = payload;
        },

        //TEMPLATE
        /**
         * Sets a template
         * @param {Object} state 
         * @param {String} payload document template
         */
        SET_TEMPLATE(state, payload) {
            state.template = payload;
        },

        //HEADER/FOOTER
        /**
         * Sets whether the document has a Header or not
         * @param {Object} state 
         * @param {Bool} payload True/False
         */
        SET_HAS_HEADER(state, payload) {
            state.hasHeader = payload;
        },
        /**
         * Sets whether the document has a Footer or not
         * @param {Object} state 
         * @param {Bool} payload True/False
         */
        SET_HAS_FOOTER(state, payload) {
            state.hasFooter = payload;
        },
    },
    actions: {
        /**
         * Updates all document properties
         * @param {Object} state 
         * @param {Object} payload new document properties
         */
        updateDocument({ commit }, payload) {
            commit("SET_ID", payload.id);
            commit("SET_TITLE", payload.title);
            commit("SET_DYNAMIC_FIELDS", payload.dynamicFields);
            commit("SET_TEMPLATE", payload.template);

            if (payload.content) {
                commit("SET_HAS_HEADER", payload.content.content.some(e => e.type === "Header"));
                commit("SET_HAS_FOOTER", payload.content.content.some(e => e.type === "Footer"));
            }
        },
        /**
         * Resets all document properties
         * @param {Object} state 
         * @param {Object} payload new document properties
         */
        resetDocument({ commit }) {
            commit("SET_ID", null);
            commit("SET_TITLE", "");
            commit("SET_DYNAMIC_FIELDS", []);
        },
    },
}