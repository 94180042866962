import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';
import { isUserLoggedIn } from '@/auth/utils';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/sign/:hash',
      name: 'manager',
      component: () => import(/* webpackChunkName: "manager" */'@/views/Manager.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Signature visuelle',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import(/* webpackChunkName: "404" */'@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   // Redirect to login if not logged in
//   if (to.name !== 'etude' && !isLoggedIn) return next({ name: 'etude' })

//   return next()
// })

export default router
